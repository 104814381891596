/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useRef, useEffect, useState } from 'react';
import { Typography, IconButton, Paper, Tooltip } from '@mui/material';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import {
  setThinkingEnabled,
  toggleThinkingDialog,
} from '@ink-ai/portal/reducers/thinking';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';

const DialogContainer = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  bottom: '60px',
  right: '10px',
  width: '310px',
  maxHeight: '300px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
}));

const TitleBar = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1, 2),
  fontWeight: 'bold',
}));

const ContentArea = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: '250px',
  minHeight: '100px',
  overflowY: 'auto',
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: '80px',
  right: '20px',
  zIndex: 9999,
  pointerEvents: 'auto',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  width: '48px',
  height: '48px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  '.close-button': {
    display: 'none',
    position: 'absolute',
    top: '-0.5rem',
    right: '-0.5rem',
    cursor: 'pointer',
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    '.close-button': {
      display: 'block',
    },
  },
  '&.hidden': {
    display: 'none',
  },
}));

interface ThinkingDialogProps {
  content: string;
}

const ThinkingDialog: React.FC<ThinkingDialogProps> = ({ content }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [dots, setDots] = useState('');

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [content]);

  useEffect(() => {
    if (!content) {
      const interval = setInterval(() => {
        setDots((prev) => (prev.length >= 3 ? '' : prev + '.'));
      }, 500);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [content]);

  return (
    <DialogContainer elevation={3}>
      <TitleBar>
        <Typography variant="subtitle2">Thinking Content</Typography>
      </TitleBar>
      <ContentArea ref={contentRef}>
        <Typography variant="body2">{content || `Thinking${dots}`}</Typography>
      </ContentArea>
    </DialogContainer>
  );
};

const ThinkingButtonComponent: React.FC<{
  isDialogOpen: boolean;
  thinkingContent: string;
  isThinkingEnabled: boolean;
  onToggle: () => void;
}> = ({ isDialogOpen, thinkingContent, isThinkingEnabled, onToggle }) => {
  const dispatch = useDispatch();
  return (
    <ButtonWrapper>
      {isDialogOpen && <ThinkingDialog content={thinkingContent} />}
      <Tooltip title="View thinking process" placement="left">
        <StyledIconButton
          onClick={onToggle}
          className={isThinkingEnabled ? '' : 'hidden'}
          size="large"
        >
          <PsychologyIcon />
          <CancelIcon
            color="error"
            className="close-button"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setThinkingEnabled(false));
            }}
          />
        </StyledIconButton>
      </Tooltip>
    </ButtonWrapper>
  );
};

export const ThinkingButton: React.FC = () => {
  const dispatch = useDispatch();
  const { isDialogOpen, thinkingContent, isThinkingEnabled } = useSelector(
    (state: RootState) => state.thinking,
  );

  const handleToggleDialog = () => {
    dispatch(toggleThinkingDialog());
  };

  return ReactDOM.createPortal(
    <ThinkingButtonComponent
      isDialogOpen={isDialogOpen}
      thinkingContent={thinkingContent}
      isThinkingEnabled={isThinkingEnabled}
      onToggle={handleToggleDialog}
    />,
    document.body,
  );
};
